.notion__full_page{
    height: 100vh;
    width: 100%;
}


.notion-collection-page-properties{
    display: none;
}

.notion-equation-block{
    align-items: center;
}
